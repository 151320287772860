// User clicks on Exportovat button and we decide here if hidden form with IDs of postings will be sent or
// button_link clicked which will export all of the rendered postings
// We also set value of a hidden field in the form to know which button was pushed, they go to same route
// @param {string} exportType - The type of export ('all_export' or 'only_not_exported').
$(document).ready(function() {
    function handleExport(event, exportType) {
        event.preventDefault();

        // Set 'export_type' hidden field in the form
        $('#export_type').val(exportType);

        const selectedIds = $('input[name="postings[]"]:checked').map(function() {
            return this.value;
        }).get();

        if (selectedIds.length > 0) {
            $('#selected_posting_ids').val(selectedIds.join(','));
            $('#export_checked_postings_form').submit();
        } else {
            const currentParams = new URLSearchParams(window.location.search);
            const exportUrl = $('#export_posting_listing').attr('href');
            const url = new URL(exportUrl, window.location.origin);
            const exportType = $('#export_type').val() || 'export_all';
            currentParams.set('export_type', exportType);
            url.search = currentParams.toString();
            window.location.href = url.toString();
        }
    }

    $('#export_posting_button').on('click', function(e) {
        const exportType = $(this).data('export-type') || 'export_all';
        handleExport.call(this, e, exportType);
    });

    $('#only_not_exported').on('click', function(e) {
        const exportType = $(this).data('export-type') || 'only_not_exported';
        handleExport.call(this, e, exportType);
    });

    $('#select_all_checkbox').on('change', function() {
        const isChecked = $(this).is(':checked');
        $('input[type="checkbox"][id^="postings_"]').prop('checked', isChecked);
    });

    // When no checkboxes are selected
    $('#export_posting_listing').on('click', function(event) {
        event.preventDefault();
        const exportType = $(this).data('export-type');
        const currentParams = new URLSearchParams(window.location.search);

        if (exportType) {
            currentParams.set('export_type', exportType);
        }

        const exportUrl = new URL($(this).attr('href'), window.location.origin);
        exportUrl.search = currentParams.toString();
        window.location.href = exportUrl.toString();
    });
});
